
import * as Li9za2lrby5tanM from './skiko.mjs';
import * as _ref_QGpzLWpvZGEvY29yZQ_ from '@js-joda/core';
import * as _ref_QGpzLWpvZGEvdGltZXpvbmU_ from '@js-joda/timezone';
import { instantiate } from './appLite.uninstantiated.mjs';


const exports = (await instantiate({
    './skiko.mjs': Li9za2lrby5tanM,
    '@js-joda/core': _ref_QGpzLWpvZGEvY29yZQ_,
    '@js-joda/timezone': _ref_QGpzLWpvZGEvdGltZXpvbmU_
})).exports;

export const {
    onHistoryPopState,
    onInternetStatusChange,
    onSocketIORecvData,
    onGoogleSignInSuccess,
    onGoogleSignInFailure,
    memory,
    _initialize,
    startUnitTests
} = exports;

